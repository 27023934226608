import { Component, Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import BaseLayout from 'src/layouts/BaseLayout';
import SidebarLayout from 'src/layouts/SidebarLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { PrivateRoute } from './components/PrivateRoute';
import { ROLE } from './models/roles';
import ReuseCategoryView from 'src/content/pages/reuse/reuse-category-view/reuse-category-view';

const Loader = Component => props => (
	<Suspense fallback={<SuspenseLoader />}>
		<Component {...props} />
	</Suspense>
);

//REUSE
const ReuseLanding = Loader(
	lazy(
		() =>
			import('src/content/pages/reuse/reuse-landing-view/reuse-landing-view'),
	),
);
const ReuseCategory = Loader(
	lazy(
		() =>
			import('src/content/pages/reuse/reuse-category-view/reuse-category-view'),
	),
);

const PackingProcessView = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/Operations/packing-process-view/packing-process-view'
			),
	),
);

//AUTH
const LoginPage = Loader(lazy(() => import('src/content/pages/auth/Login')));
const RequestPasswordPage = Loader(
	lazy(() => import('src/content/pages/auth/RecoverPassword/RequestPassword')),
);
const RequestPasswordResetPage = Loader(
	lazy(
		() => import('src/content/pages/auth/RecoverPassword/RequestPasswordReset'),
	),
);

//USERS
const UserProfile = Loader(
	lazy(() => import('src/content/pages/users/UserProfile')),
);

// WASTE ORDERS
const CreateWasteOrder = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/waste-orders/create-waste-order-view/create-waste-order'
			),
	),
);

const WasteOrderView = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/waste-orders/waste-order-view/waste-order-view'
			),
	),
);

const WasteOrdersView = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/waste-orders/waste-orders-view/waste-orders-view'
			),
	),
);

const WasteOrderEditView = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/waste-orders/waste-order-edit-view/waste-order-edit-view'
			),
	),
);

//RECEPTIONS
const ReceptionDetailView = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/receptions/reception-detail-view/reception-details-view'
			),
	),
);
const ReceptionListView = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/receptions/reception-list-view/reception-list-view'
			),
	),
);

//SHIPMENT
const ShipmentListView = Loader(
	lazy(
		() =>
			import('src/content/pages/shipments/shipment-list/shipment-list-view'),
	),
);
const ShipmentDeatilView = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/shipments/shipment-detail-view/shipment-detail-view'
			),
	),
);
const ReceptionCreateView = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/receptions/reception-create-view/reception-create-view'
			),
	),
);

const ReceptionFinishView = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/receptions/reception-perform-view/reception-perform-view'
			),
	),
);

const ShipmentCreateProductView = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/shipments/shipment-create-product-view/shipment-create-product-view'
			),
	),
);
const ShipmentEditView = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/shipments/shipment-edit-view/shipment-edit-view'
			),
	),
);
//RECOGIDA
const PickupListView = Loader(
	lazy(
		() =>
			import('src/content/pages/pickup/pickup-detail-vies/pickup-detail-view'),
	),
);

//RUTAS
const PresetRoutesCreate = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/routes/preset-route-create-view/preset-route-create-view'
			),
	),
);
const RoutesCreate = Loader(
	lazy(
		() =>
			import('src/content/pages/routes/route-create-view/route-create-view'),
	),
);
//PRODUCTS
const ProductsList = Loader(
	lazy(() => import('src/content/pages/products/ProductsList')),
);
const ProductDetail = Loader(
	lazy(() => import('src/content/pages/products/ProductDetail')),
);
const EditProductView = Loader(
	lazy(
		() =>
			import('src/content/pages/products/edit-product-view/edit-product-view'),
	),
);

// WAREHOUSE
const warehouseView = Loader(
	lazy(() => import('src/content/pages/warehouse-view/warehouse-view')),
);

//PROCESS
const ProcessPacked = Loader(
	lazy(() => import('src/content/pages/Operations/ProcessPacked')),
);

//OPERATIONS
const Operations = Loader(lazy(() => import('src/content/pages/Operations')));
const Packaging = Loader(
	lazy(() => import('src/content/pages/Operations/Packaging')),
);
const SackPackaging = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/Operations/sacking-process/sacking-process-view'
			),
	),
);
const DumpContainer = Loader(
	lazy(() => import('src/content/pages/Operations/DumpContainer')),
);
const WeighContainer = Loader(
	lazy(
		() =>
			import('src/content/pages/Operations/WeighContainer/weigh-process-view'),
	),
);
const Cutting = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/Operations/cutting-processes/cutting-processes-view'
			),
	),
);
const CuttingTable = Loader(
	lazy(
		() =>
			import(
				'src/content/pages/Operations/cutting-process/cutting-process-view'
			),
	),
);
const Pickup = Loader(
	lazy(
		() =>
			import('src/content/pages/Operations/pickup-process/pickup-process-view'),
	),
);

//CONTAINERS
const ContainersList = Loader(
	lazy(() => import('src/content/pages/containers/ContainersList')),
);
const ContainersWamList = Loader(
	lazy(() => import('src/content/pages/containers/ContainersList')),
);
const MultiplePrint = Loader(
	lazy(
		() => import('src/content/pages/containers/multiplePrint/multiplePrint'),
	),
);

//ADMINISTRATION
const LogisticCentersList = Loader(
	lazy(() => import('src/content/pages/centers/LogisticCentersList')),
);
const LogisticCentersCreate = Loader(
	lazy(() => import('src/content/pages/centers/LogisticCentersCreate')),
);
const LogisticCentersEdit = Loader(
	lazy(() => import('src/content/pages/centers/LogisticCenterEdit')),
);
const ScalesList = Loader(
	lazy(() => import('src/content/pages/scales/ScalesList')),
);
const ScalesEdit = Loader(
	lazy(() => import('src/content/pages/scales/ScalesEdit')),
);
const ScaleCreate = Loader(
	lazy(() => import('src/content/pages/scales/ScaleCreate')),
);

const InventoryFormView = Loader(
	lazy(() => import('src/content/pages/inventory-view/inventory-view')),
);

const AdminView = Loader(
	lazy(() => import('src/content/pages/admin-view/admin-view')),
);

const ALL_USERS = [
	ROLE.SUPER_ADMIN,
	ROLE.ADMIN,
	ROLE.TRACEABILITY,
	ROLE.SELLING,
	ROLE.SHIPPING,
	ROLE.STORE,
	ROLE.RECEPTION,
	ROLE.TRANSFORMATION,
	ROLE.SUPER_ADMIN,
	ROLE.CLASIFICATION,
	ROLE.MOBILE_APP,
];
const routes: RouteObject[] = [
	{
		path: 'reuse',
		element: <ReuseLanding />,
	},
	{
		path: 'reuse/category',
		element: <ReuseCategory />,
	},
	{
		path: 'admin',
		element: <SidebarLayout />,
		children: [
			{
				path: '',
				element: <AdminView />,
			},
		],
	},
	{
		path: 'settings',
		element: <SidebarLayout />,
		children: [
			{
				path: '',
				element: <Navigate to="userprofile" replace />,
			},
			{
				path: 'userprofile',
				element: (
					<PrivateRoute
						roles={[
							ROLE.SUPER_ADMIN,
							ROLE.ADMIN,
							ROLE.TRACEABILITY,
							ROLE.SELLING,
							ROLE.SHIPPING,
							ROLE.STORE,
							ROLE.RECEPTION,
							ROLE.TRANSFORMATION,
							ROLE.SUPER_ADMIN,
							ROLE.CLASIFICATION,
							ROLE.MOBILE_APP,
						]}
						component={UserProfile}
					/>
				),
			},
		],
	},
	{
		path: '',
		element: <BaseLayout />,
		children: [
			{
				path: '/',
				element: <LoginPage />,
			},
			{
				path: 'login',
				element: <Navigate to="/" replace />,
			},
			{
				path: 'recover-password',
				element: <RequestPasswordPage />,
			},
			{
				path: 'recovery-password',
				element: <RequestPasswordResetPage />,
			},
		],
	},
	{
		path: 'management',
		element: <SidebarLayout />,
		children: [
			{
				path: 'operations',
				element: <PrivateRoute roles={ALL_USERS} component={Operations} />,
			},
			{
				path: 'inventory',
				element: (
					<PrivateRoute roles={ALL_USERS} component={InventoryFormView} />
				),
			},
			{
				path: 'warehouse',
				element: <PrivateRoute roles={ALL_USERS} component={warehouseView} />,
			},
			{
				path: 'waste-orders/create',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
						component={CreateWasteOrder}
					/>
				),
			},
			{
				path: 'waste-orders',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
						component={WasteOrdersView}
					/>
				),
			},
			{
				path: 'waste-orders/:id',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
						component={WasteOrderView}
					/>
				),
			},
			{
				path: 'waste-orders/:id/edit',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
						component={WasteOrderEditView}
					/>
				),
			},
			{
				path: 'reception/:id',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
						component={ReceptionDetailView}
					/>
				),
			},
			{
				path: 'receptions',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
						component={ReceptionListView}
					/>
				),
			},
			{
				path: 'shipments',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
						component={ShipmentListView}
					/>
				),
			},
			{
				path: 'reception/create',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
						component={ReceptionCreateView}
					/>
				),
			},
			{
				path: 'reception/finish/:id',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
						component={ReceptionFinishView}
					/>
				),
			},
			{
				path: 'shipments/create',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
						component={ShipmentCreateProductView}
					/>
				),
			},
			{
				path: 'shipment/:id',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
						component={ShipmentDeatilView}
					/>
				),
			},
			{
				path: 'shipment/:id/edit',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
						component={ShipmentEditView}
					/>
				),
			},
			{
				path: 'pickups',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
						component={PickupListView}
					/>
				),
			},
			{
				path: 'preset-routes/create',
				element: (
					<PrivateRoute roles={ALL_USERS} component={PresetRoutesCreate} />
				),
			},
			{
				path: 'routes/create',
				element: <PrivateRoute roles={ALL_USERS} component={RoutesCreate} />,
			},
			{
				path: 'lots/all',
				element: <PrivateRoute roles={ALL_USERS} component={ProductsList} />,
			},
			{
				path: 'lots/packed',
				element: <PrivateRoute roles={ALL_USERS} component={ProcessPacked} />,
			},
			{
				path: 'lots/details/:id',
				element: <PrivateRoute roles={ALL_USERS} component={ProductDetail} />,
			},
			{
				path: 'lots/details/:id/edit',
				element: <PrivateRoute roles={ALL_USERS} component={EditProductView} />,
			},
			{
				path: 'process/packed',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
						component={ProcessPacked}
					/>
				),
			},
			{
				path: 'containers',
				element: (
					<PrivateRoute roles={ALL_USERS} component={ContainersWamList} />
				),
			},
			{
				path: 'operations',
				element: <PrivateRoute roles={ALL_USERS} component={Operations} />,
			},
			{
				path: 'operations/packaging',
				element: <PrivateRoute roles={ALL_USERS} component={Packaging} />,
			},
			{
				path: 'operations/sackpackaging',
				element: <PrivateRoute roles={ALL_USERS} component={SackPackaging} />,
			},
			{
				path: 'operations/dump-container',
				element: <PrivateRoute roles={ALL_USERS} component={DumpContainer} />,
			},
			{
				path: 'operations/weigh-container',
				element: <PrivateRoute roles={ALL_USERS} component={WeighContainer} />,
			},
			{
				path: 'operations/packing-process',
				element: (
					<PrivateRoute roles={ALL_USERS} component={PackingProcessView} />
				),
			},
			{
				path: 'operations/cutting',
				element: <PrivateRoute roles={ALL_USERS} component={Cutting} />,
			},
			{
				path: 'operations/cutting/:id',
				element: <PrivateRoute roles={ALL_USERS} component={CuttingTable} />,
			},
			{
				path: 'administration/scales',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
						component={ScalesList}
					/>
				),
			},
			{
				path: 'administration/container',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
						component={ContainersList}
					/>
				),
			},
			{
				path: 'administration/container/multiPrint',
				element: <PrivateRoute roles={ALL_USERS} component={MultiplePrint} />,
			},
			{
				path: 'administration/logistic-centers',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
						component={LogisticCentersList}
					/>
				),
			},
			{
				path: 'administration/logistic-centers/create',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
						component={LogisticCentersCreate}
					/>
				),
			},
			{
				path: 'administration/logistic-centers/edit/:id',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
						component={LogisticCentersEdit}
					/>
				),
			},
			{
				path: 'administration/dashboard',
				element: <PrivateRoute roles={ALL_USERS} component={Operations} />,
			},
			{
				path: 'administration/scales',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
						component={ScalesList}
					/>
				),
			},
			{
				path: 'administration/scales/edit/:id',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
						component={ScalesEdit}
					/>
				),
			},
			{
				path: 'administration/scales/create-scale',
				element: (
					<PrivateRoute
						roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
						component={ScaleCreate}
					/>
				),
			},
		],
	},
];

export default routes;
