import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { User } from 'src/models/users';

const BASE_URL_API = process.env.REACT_APP_BASE_URL;
export interface UserResponse {
	user: User;
	auth: IAuthTokens;
	message: string;
}

export interface IAuthTokens {
	accessToken: string;
	refreshToken: string;
}

export interface LoginRequest {
	email: string;
	password: string;
}

export interface RegisterRequest {
	organization: Object;
	user: Object;
}

export interface RegisterResponse {
	message: string;
	organization: Object;
	user: Object;
}

export interface ResetPasswordRequest {
	email: string;
}

export interface ResetPassword {
	id: number;
	password: string;
}

export const apiAuth = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL_API,
		prepareHeaders: headers => {
			const token = null;

			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}

			return headers;
		},
	}),
	endpoints: builder => ({
		login: builder.mutation<UserResponse, LoginRequest>({
			query: credentials => ({
				url: 'api/auth/login',
				method: 'POST',
				body: credentials,
			}),
			transformResponse: (response: UserResponse, meta, arg) => {
				return {
					...response,
					action: 'login',
				};
			},
		}),
		register: builder.mutation<RegisterResponse, RegisterRequest>({
			query: registerData => ({
				url: 'api/auth/register',
				method: 'POST',
				body: registerData,
			}),
		}),
		resetPasswordRequest: builder.mutation<void, ResetPasswordRequest>({
			query: ResetPasswordRequest => ({
				url: 'api/auth/reset-password-request',
				method: 'POST',
				body: ResetPasswordRequest,
			}),
		}),
		resetPassword: builder.mutation<void, ResetPassword>({
			query: ResetPasswordRequest => ({
				url: 'api/auth/reset-password',
				method: 'POST',
				body: ResetPasswordRequest,
			}),
		}),
	}),
});

export const {
	useLoginMutation,
	useRegisterMutation,
	useResetPasswordRequestMutation,
	useResetPasswordMutation,
} = apiAuth;
